<template>
  <PageContent class="liste">
    <template #header-right>
      <ButtonClassic
        variant="ghost"
        :label="$t('action.creer-liste')"
        :disabled="isImpersonating"
        icon="right"
        color="primary"
        @click="openCreateListModal()"
      >
        <template #right-icon>
          <UilPlus />
        </template>
      </ButtonClassic>
      <ContextMenu
        :bloc-actions-globales="actionsGlobales"
        :bloc-actions-selections="actionsSelections"
        :selections="selections"
        @click-action="handleActions($event)"
      />
    </template>

    <template #content>
      <div
        v-if="noList && !loadingActiveLists && !loadingTransferedLists"
        class="empty-page"
      >
        <h2 class="s4 light">
          {{ $t('liste.aucune-liste') }}
        </h2>
        <img
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_cart.svg')"
          :alt="$t('general.alt-image-illustration')"
        />
      </div>

      <template v-else>
        <section class="bloc-listes">
          <h2 class="s5 title-separator">
            <span class="bold">{{ $t("liste.listes-en-cours") }}</span>
          </h2>
          <div v-if="loadingActiveLists">
            <InfiniteLoader />
          </div>
          <template v-else>
            <template v-if="activeLists?.length">
              <ListesTable
                :rows="activeLists"
                :active-sort="activeSort"
                @click-delete="showConfirmDeletion([$event, ])"
                @click-transfer="showConfirmTransfer([$event, ])"
                @sort="handleSort($event, 'active')"
              />
              <div
                v-if="totalRows > pageSize"
                class="pagination-container"
              >
                <Pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="pageSize"
                  @change="(page) => $router.push({ query: { ...$route.query, page, }, })"
                />
              </div>
            </template>
            <p
              v-else
              class="text-medium light"
            >
              {{ $t("liste.aucune-liste-dans-cette-categorie") }}
            </p>
          </template>
        </section>

        <section class="bloc-listes">
          <h2 class="s5 title-separator">
            <span class="bold">{{ $t("liste.listes-envoyes-a-des-collegues") }}</span>
          </h2>
          <div v-if="loadingTransferedLists">
            <InfiniteLoader />
          </div>
          <template v-else>
            <template v-if="transferedLists?.length">
              <ListesTable
                :rows="transferedLists"
                :active-sort="activeSortTransfer"
                variant="transfer"
                @sort="handleSort($event, 'transfer')"
              />
              <div
                v-if="totalRowsTransfer > pageSize"
                class="pagination-container"
              >
                <Pagination
                  v-model="currentPageTransfer"
                  :total-rows="totalRowsTransfer"
                  :per-page="pageSize"
                  @change="(page) => $router.push({ query: { ...$route.query, page_transfered: page, }, })"
                />
              </div>
            </template>
            <p
              v-else
              class="text-medium light"
            >
              {{ $t("liste.aucune-liste-dans-cette-categorie") }}
            </p>
          </template>
        </section>
      </template>

      <ModalConfirmDeletion
        :to-delete="listsToDelete"
        variant="liste"
        @confirm="handleAcceptAction('deletion')"
      />
      <ModalListesConfirmTransfer
        :listes="listsToTransfer"
        @confirm="handleAcceptAction('transfer', $event)"
      />
      <ModalListesConfirmMerge
        :lists="listsToMerge"
        @confirm="handleAcceptAction('merge', $event)"
      />
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  PageContent,
  Pagination,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import ContextMenu from "@/components/ContextMenu.vue";
import ListesTable from "@/components/table/ListesTable.vue";
import ModalConfirmDeletion from "@/components/modals/ModalConfirmDeletion.vue";
import ModalListesConfirmTransfer from "@/components/modals/ModalListesConfirmTransfer.vue";
import ModalListesConfirmMerge from "@/components/modals/ModalListesConfirmMerge.vue";

import IconBookToDevisRight from "@/components/icons/IconBookToDevisRight.vue";
import IconFusion from "@/components/icons/IconFusion.vue";

import { UilPlus, UilTrashAlt } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

import Api from "@/modules/axios";

/**
 * Vue des listes accessibles par le compte courant.
 */
export default {
  name: "Liste",
  components: {
    PageContent,
    ButtonClassic,
    Pagination,
    ContextMenu,
    ListesTable,
    ModalConfirmDeletion,
    ModalListesConfirmTransfer,
    ModalListesConfirmMerge,
    InfiniteLoader,
    UilPlus,
  },
  data() {
    return {
      activeLists: [],
      transferedLists: [],
      loadingActiveLists: false,
      loadingTransferedLists: false,
      listsToDelete: [],
      listsToTransfer: [],
      listsToMerge: [],
      pageSize: 24,
      currentPage: 1,
      currentPageTransfer: 1,
      totalRows: 0,
      totalRowsTransfer: 0,
      activeSort: {},
      activeSortTransfer: {},
    };
  },
  computed: {
    ...mapGetters(["organismeActuel"]),
    noList() {
      return !this.activeLists.length && !this.transferedLists.length;
    },
    selections() {
      return this.activeLists.filter((liste) => liste._checked);
    },
    actionsGlobales() {
      return [{
        title: this.$t("action.creation-de-liste"),
        actions: [
          {
            slug: "create_list",
            label: this.$t("action.creer-liste"),
            icon: UilPlus,
            disabled: this.isImpersonating,
          },
          {
            slug: "create_quick_list",
            label: this.$t("action.faire-saisie-rapide"),
            icon: UilPlus,
            disabled: this.isImpersonating,
          },
        ],
      }];
    },
    actionsSelections() {
      return [
        {
          slug: "transfer_selected",
          label: this.$t("liste.envoyer"),
          icon: IconBookToDevisRight,
          disabled: this.isImpersonating || !this.selections.length,
        },
        {
          slug: "merge_selected",
          label: this.$t("liste.fusionner-listes"),
          icon: IconFusion,
          disabled: this.isImpersonating || !this.selections.length,
        },
        {
          slug: "delete_selected",
          label: this.$t("action.supprimer"),
          icon: UilTrashAlt,
          disabled: this.isImpersonating || !this.selections.length,
        },
      ];
    },
  },
  watch: {
    /**
     * Quand la recherche change dans la queryString.
     */
    "$route.query": {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const changeActive = {};
          const changeTransfer = {};

          const toNumber = (value) => parseInt(value, 10);

          if (toNumber(newValue.page) !== toNumber(oldValue.page)) {
            this.currentPage = toNumber(newValue.page) || 1;
            changeActive.paginating = true;
          }
          if (newValue.sort !== oldValue.sort) {
            this.activeSort.sortAscended = newValue.sort.charAt(0) !== "-";
            this.activeSort.key = newValue.sort.replace("-", "");
            changeActive.sorting = true;
          }

          if (toNumber(newValue.page_transfered) !== toNumber(oldValue.page_transfered)) {
            this.currentPageTransfer = toNumber(newValue.page_transfered) || 1;
            changeTransfer.paginating = true;
          }
          if (newValue.sort_transfered) {
            this.activeSortTransfer.sortAscended = newValue.sort_transfered.charAt(0) !== "-";
            this.activeSortTransfer.key = newValue.sort_transfered.replace("-", "");
            changeTransfer.sorting = true;
          }

          if (changeActive.paginating || changeActive.sorting) {
            this.fetchActiveLists();
          }
          if (changeTransfer.paginating || changeTransfer.sorting) {
            this.fetchTransferedLists();
          }
        }
      },
    },
  },
  mounted() {
    // Si on a déjà des query param en arrivant sur la page
    const {
      page,
      sort,
      pageTransfered,
      sortTransfered,
      saisieRapide,
    } = this.$route.query;

    if (page) {
      this.currentPage = parseInt(page, 10);
    }
    if (sort) {
      this.activeSort.sortAscended = sort.charAt(0) !== "-";
      this.activeSort.key = sort.replace("-", "");
    }

    if (pageTransfered) {
      this.currentPageTransfer = parseInt(page, 10);
    }
    if (sortTransfered) {
      this.activeSortTransfer.sortAscended = sortTransfered.charAt(0) !== "-";
      this.activeSortTransfer.key = sortTransfered.replace("-", "");
    }

    this.fetchAllLists();

    // Paramètre venant de home > accès rapide > faire une saisie rapide.
    if (saisieRapide) {
      this.openCreateListModal();
    }
  },
  methods: {
    /**
     * Trie les données du tableau selon la valeur choisie.
     * @param {Object} sort Clé et sens du tri.
     * @param {String} table Détermine le tableau sur lequel on trie.
     */
    handleSort(sort, table = "active") {
      const query = { ...this.$route.query };

      if (table === "active") {
        query.sort = `${sort.sortAscended ? "" : "-"}${sort.key}`;
        query.page = 1;
      }

      if (table === "transfer") {
        query.sort_transfered = `${sort.sortAscended ? "" : "-"}${sort.key}`;
        query.page_transfered = 1;
      }

      this.$router.push({ query });
    },
    /**
     * Récupère les listes non transférées.
     * @returns {Promise} Listes non transférées.
     */
    fetchActiveLists() {
      this.loadingActiveLists = true;

      const params = {
        organisme: this.organismeActuel.id,
        page: this.currentPage,
        page_size: this.pageSize,
        date_transfert__isnull: true,
        panier: false,
      };
      if (this.activeSort.key) {
        params.ordering = `${this.activeSort.sortAscended ? "" : "-"}${this.activeSort.key}`;
      }

      return Api().get("/liste/", { params })
        .then(({ data: { results, count } }) => {
          this.activeLists = results.map((res) => {
            res._routeName = "listes_devis_listes_item";
            return res;
          });
          this.totalRows = count;
          this.loadingActiveLists = false;
        })
        .catch((err) => {
          this.$toast.error({
            title: err.response
              ? (err.response.data.detail || (Array.isArray(err.response.data) && err.response.data[0]))
                || this.$t("info.une-erreur-est-survenue")
              : this.$t("info.une-erreur-est-survenue"),
          });
        });
    },
    /**
     * Récupère les listes transférées.
     * @returns {Promise} Listes transférées.
     */
    fetchTransferedLists() {
      this.loadingTransferedLists = true;

      let url = `/liste/?organisme=${this.organismeActuel.id}&page=${this.currentPageTransfer}&page_size=${
        this.pageSize}&date_transfert__isnull=false&panier=false`;

      if (this.activeSortTransfer.key) {
        url += `&ordering=${this.activeSortTransfer.sortAscended ? "" : "-"}${this.activeSortTransfer.key}`;
      }

      return Api().get(url)
        .then(({ data: { results, count } }) => {
          this.transferedLists = results.map((list) => {
            list._variant = "dashed";
            list._disabled = true;
            return list;
          });
          this.totalRowsTransfer = count;
        })
        .catch((err) => {
          this.$toast.error({
            title: err.response ? (err.response.data.detail
                || (Array.isArray(err.response.data) && err.response.data[0]))
                || this.$t("info.une-erreur-est-survenue")
              : this.$t("info.une-erreur-est-survenue"),
          });
        })
        .finally(() => { this.loadingTransferedLists = false; });
    },
    /**
     * Récupère toutes les listes transférées ou non.
     */
    fetchAllLists() {
      return Promise.all([this.fetchActiveLists(), this.fetchTransferedLists()]);
    },
    /**
     * Gère les actions du menu contextuel.
     * @param {Object} action contenant les infos de l'action.
     */
    handleActions(action) {
      switch (action.slug) {
        case "transfer_selected":
          this.showConfirmTransfer(this.selections);
          break;
        case "delete_selected":
          this.showConfirmDeletion(this.selections);
          break;
        case "merge_selected":
          this.showConfirmMerge(this.selections);
          break;
        case "create_list":
        case "create_quick_list":
          this.openCreateListModal();
          break;
        default:
          break;
      }
    },
    /**
     * Ouvre la modale de création de liste.
     */
    openCreateListModal() {
      this.$modal.show("modal_listes_creation");
    },
    /**
     * Affiche la modale de confirmation de suppression.
     * @param {Array} listes Listes sélectionnées.
     * @param {Boolean} fromContextual Si true, on effectue l'action depuis le menu contextuel.
     */
    showConfirmDeletion(listes, fromContextual) {
      this.$matomo.trackEvent(
        `delete_liste${fromContextual ? "_contextual" : ""}`,
        "click",
        `Sélection: ${listes.length}`,
      );
      this.listsToDelete = listes;
      this.$modal.show("modal_confirm_deletion");
    },
    /**
     * Affiche la modale de confirmation de transfert.
     * @param {Array} listes Listes sélectionnées.
     * @param {Boolean} fromContextual Si true, on effectue l'action depuis le menu contextuel.
     */
    showConfirmTransfer(listes, fromContextual) {
      this.$matomo.trackEvent(
        `transfert_liste${fromContextual ? "_contextual" : ""}`,
        "click",
        `Sélection: ${listes.length}`,
      );
      this.listsToTransfer = listes;
      const hasEmptyList = this.listsToTransfer.some((list) => list.quantite_ligne === 0);
      if (hasEmptyList) {
        this.$toast.error({ title: this.$t("liste.impossible-transferer-liste-vide") });
      } else {
        this.$modal.show("modal_listes_confirm_transfer");
      }
    },
    /**
     * Affiche la modale de confirmation de fusion.
     * @param {Array} listes Listes sélectionnées.
     */
    showConfirmMerge(listes) {
      if (this.selections.length >= 2) {
        this.$matomo.trackEvent(
          "fusion_liste",
          "click",
          `Sélection: ${listes.length}`,
        );
        this.listsToMerge = listes.map(({ id }) => id);
        this.$modal.show("modal_listes_confirm_merge");
      } else {
        this.$toast.error({ title: this.$tc("general.selectionnez-au-moins-x-elements", 2, { nombre: 2 }) });
      }
    },
    /**
     * Effectue une requête pour chaque ligne sélectionnée selon l'action qui a été acceptée.
     * @param {String} type Définit si on supprime ou si on transfère la liste.
     * @param {Object} infos Paramètres additionnels (nom de liste, utilisateur…).
     */
    handleAcceptAction(type, infos = null) {
      let promises = [];

      if (type === "deletion") {
        this.loadingActiveLists = true;
        promises = this.listsToDelete.map((liste) => Api()
          .delete(`/liste/${liste.id}/`)
          .then(() => liste));
      } else if (type === "transfer") {
        this.loadingActiveLists = true;
        promises = this.listsToTransfer.map((liste) => Api()
          .post(`/liste/${liste.id}/transfer_to_user/`, { user_id: infos.id })
          .then(() => liste));
      } else if (type === "merge") {
        promises = [Api()
          .post("/liste/merge/", { libelle: infos.listName, listes_ids: this.listsToMerge })
          .then(({ data }) => {
            this.$modal.hide("modal_listes_confirm_merge");
            return data;
          }),
        ];
      }

      Promise.all(promises)
        .then((res) => {
          // Mettre à jour les listes dans le store.
          this.$store.dispatch("fetchActiveLists");

          if (type === "merge") {
            this.$router.push({ name: "listes_devis_listes_item", params: { id: res[0].id } });
            this.$toast.success({ title: this.$t("liste.listes-bien-fusionnees") });
          } else {
            const fetch = type === "transfer" ? this.fetchAllLists() : this.fetchActiveLists();

            fetch.then(() => {
              this.loadingActiveLists = false;

              if (type === "deletion") {
                this.$toast.success({
                  title: this.listsToDelete.length === 1
                    ? this.$t("liste.liste-x-bien-supprimee", { libelle: this.listsToDelete[0].libelle })
                    : this.$t("liste.listes-bien-supprimees"),
                });
              } else if (type === "transfer") {
                this.$toast.success({
                  title: this.listsToTransfer.length === 1
                    ? this.$t("liste.liste-x-bien-transferee", { libelle: this.listsToTransfer[0].libelle })
                    : this.$t("liste.listes-bien-transferees"),
                });
              }
            });
          }
        })
        .catch((err) => {
          this.$toast.error({ title: err.response.data.detail || err.response.data });
        });
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

.liste {
  .bloc-listes {
    &:not(:last-of-type) { margin-bottom: var.$space-x-large; }
    > h2 { margin-bottom: var.$space-tiny; }
  }
}
</style>
